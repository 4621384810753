:root {
    --base-color: #fd6f63;
}

.comments__list,
.sidebar .widget ul.category-list,
.sidebar .widget ul.insta-list,
.sidebar .widget ul.list-posts,
.sidebar .widget.social-widget ul.social-list,
.single-post .single-post-content .post-content .post-content-text .share-tags-box ul.shares-likes,
.single-post .single-post-content .post-content .post-content-text .share-tags-box ul.tags,
.single-post .single-post-content .post-content .post-social ul.share-post,
section.instagram-section .insta-list,
ul.filter-list,
ul.post-tags,
ul.posts-list {
    margin: 0;
    padding: 0;
}

a {
    display: inline-block;
    text-decoration: none !important;
    transition: all 0.2s ease-in-out;
    -moz-transition: all 0.2s ease-in-out;
    -webkit-transition: all 0.2s ease-in-out;
    -o-transition: all 0.2s ease-in-out;
}


#container {
    overflow: hidden;
    opacity: 0;
}

#container.active {
    opacity: 1;
}

.category-link {
    color: #fff;
    font-size: 10px;
    font-weight: 400;
    font-family: Roboto, sans-serif;
    text-transform: capitalize;
    padding: 4px 13px;
    background-color: var(--base-color);
    letter-spacing: 0.5px;
    margin-bottom: 15px !important;
}

.category-link a {
    color: #fff;
    font-size: 10px;
    text-decoration: none;
}

p.text-link {
    color: #e74c3c;
    font-size: 10px;
    font-weight: 400;
    font-family: Roboto, sans-serif;
    text-transform: uppercase;
    letter-spacing: 0.5px;
}

.border-bottom,
.border-top {
    border-color: #ebebeb;
}

ul.post-tags li {
    display: inline-block;
    color: #000;
    font-family: Roboto, sans-serif;
    font-weight: 400;
    font-size: 12px;
    line-height: 20px;
    margin-right: 5px;
}

ul.post-tags li a {
    color: #000;
}

ul.post-tags li a:hover {
    color: #e74c3c;
    text-decoration: underline !important;
}

ul.post-tags li:before {
    content: "|";
    color: #999;
    font-family: Roboto, sans-serif;
    font-weight: 400;
    font-size: 14px;
    margin-right: 8px;
}

ul.post-tags li:first-child:before {
    display: none;
    margin-right: 0;
}

a.button-one {
    color: #e74c3c;
    font-size: 13px;
    font-family: Roboto, sans-serif;
    padding: 13px 50px;
    background: #fff;
    font-weight: 400;
    border: 1px solid #ebebeb;
    transition: all 0.2s ease-in-out;
    -moz-transition: all 0.2s ease-in-out;
    -webkit-transition: all 0.2s ease-in-out;
    -o-transition: all 0.2s ease-in-out;
    text-transform: uppercase;
    margin: 0;
}

a.button-one i {
    display: inline-block;
    font-size: 14px;
    margin-left: 20px;
}

a.button-one:hover {
    background: #e74c3c;
    border-color: #e74c3c;
    color: #fff;
}

a.white-button {
    display: inline-block;
    color: #fff;
    font-size: 13px;
    font-family: Roboto, sans-serif;
    padding: 13px 10px;
    width: 170px;
    background: 0 0;
    font-weight: 400;
    border: 1px solid #fff;
    transition: all 0.2s ease-in-out;
    -moz-transition: all 0.2s ease-in-out;
    -webkit-transition: all 0.2s ease-in-out;
    -o-transition: all 0.2s ease-in-out;
    text-transform: uppercase;
    margin: 20px 0 0;
}

a.white-button i {
    display: inline-block;
    font-size: 14px;
    margin-left: 20px;
}

a.white-button:hover {
    background: #fff;
    color: #333;
}

.center-button {
    text-align: center;
    padding-top: 30px;
}

ul.filter-list {
    text-align: right;
    margin-bottom: 25px;
}

ul.filter-list li {
    display: inline-block;
    margin-left: 25px;
}

ul.filter-list li a {
    color: #999;
    font-size: 10px;
    font-weight: 400;
    font-family: Roboto, sans-serif;
    text-transform: uppercase;
    letter-spacing: 0.5px;
    padding-bottom: 5px;
    border-bottom: 1px solid transparent;
}

ul.filter-list li a.active,
ul.filter-list li a:hover {
    color: #e74c3c;
    border-bottom: 1px solid #e74c3c;
}

.white-style h1 {
    color: #fff;
}

.image-post {
    position: relative;
    overflow: hidden;
}

.image-post img {
    width: 100%;
    height: auto;
    transition: all 0.6s ease-in-out;
    -moz-transition: all 0.6s ease-in-out;
    -webkit-transition: all 0.6s ease-in-out;
    -o-transition: all 0.6s ease-in-out;
}

.image-post:hover img {
    animation-name: imagemove;
    animation-duration: 3s;
    animation-timing-function: ease-out;
    animation-delay: 0s;
    animation-direction: alternate;
    animation-iteration-count: infinite;
    animation-fill-mode: none;
    animation-play-state: running;
}

@keyframes imagemove {
    0% {
        -webkit-transform: rotate(0) scale(1);
        -moz-transform: rotate(0) scale(1);
        -ms-transform: rotate(0) scale(1);
        -o-transform: rotate(0) scale(1);
        transform: rotate(0) scale(1);
    }

    100% {
        -webkit-transform: rotate(5deg) scale(1.1);
        -moz-transform: rotate(5deg) scale(1.1);
        -ms-transform: rotate(5deg) scale(1.1);
        -o-transform: rotate(5deg) scale(1.1);
        transform: rotate(5deg) scale(1.1);
    }
}

.image-post .hover-post {
    position: absolute;
    padding: 30px 26px;
    bottom: 0;
    left: 0;
    right: 0;
}

.trending_posts_section img {
    height: 100% !important;
    object-fit: cover;
}

.trending_posts_section.image-post .hover-post {
    position: absolute;
    padding: 30px 26px;
    top: 50%;
    left: 50%;
    /* right: 0; */
    transform: translate(-50%, -50%);
    width: 90%;
    height: 90%;
}

.image-post .hover-post a.category-link {
    margin-bottom: 8px;
}

.image-post .hover-post h2 {
    margin-bottom: 5px;
}

.image-post .hover-post h2 a {
    color: #fff;
    font-size: 20px;
    line-height: 26px;
}

.image-post .hover-post ul.post-tags li {
    color: #fff;
}

.image-post .hover-post ul.post-tags li a {
    color: #fff;
}

.image-post .hover-post ul.post-tags li a:hover {
    color: #e74c3c;
}

.image-post .hover-post ul.post-tags li:before {
    color: #fff;
}

.image-post .hover-post p {
    color: #fff;
    margin-top: 10px;
    margin-bottom: 0;
}

.image-post .hover-post.overlay-bg {
    background: linear-gradient(to top, rgba(0, 0, 0, 0.5) 0, rgba(0, 0, 0, 0) 100%);
}

.standard-post {
    text-align: center;
}

.standard-post .image-holder {
    margin-bottom: 14px;
    overflow: hidden;
    border-radius: 0.5rem;
    height: 300px;
    width: 100%;
}

.standard-post .image-holder a {
    height: 100%;
    width: 100%;
}

.standard-post .image-holder a img {
    height: 100%;
    object-fit: cover;
}

.standard-post .image-holder:hover img {
    animation-name: imagemove;
    animation-duration: 3s;
    animation-timing-function: ease-out;
    animation-delay: 0s;
    animation-direction: alternate;
    animation-iteration-count: infinite;
    animation-fill-mode: none;
    animation-play-state: running;
}

@keyframes imagemove {
    0% {
        -webkit-transform: rotate(0) scale(1);
        -moz-transform: rotate(0) scale(1);
        -ms-transform: rotate(0) scale(1);
        -o-transform: rotate(0) scale(1);
        transform: rotate(0) scale(1);
    }

    100% {
        -webkit-transform: rotate(5deg) scale(1.1);
        -moz-transform: rotate(5deg) scale(1.1);
        -ms-transform: rotate(5deg) scale(1.1);
        -o-transform: rotate(5deg) scale(1.1);
        transform: rotate(5deg) scale(1.1);
    }
}

.standard-post img {
    width: 100%;
    height: auto;
    transition: all 0.6s ease-in-out;
    -moz-transition: all 0.6s ease-in-out;
    -webkit-transition: all 0.6s ease-in-out;
    -o-transition: all 0.6s ease-in-out;
}

.standard-post h2 {
    font-size: 18px;
    line-height: 24px;
    margin-bottom: 2px;
}

.standard-post h2 a {
    color: #333;
}

.standard-post h2 a:hover {
    color: #e74c3c;
}

.video-post {
    text-align: center;
}

.video-post .image-holder {
    margin-bottom: 17px;
    position: relative;
}

.video-post .image-holder .hover-video {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    flex-direction: columns;
}

.video-post .image-holder .hover-video a span {
    display: inline-block;
    padding: 10px;
    background: rgba(231, 76, 60, 0.4);
    -webkit-border-radius: 50%;
    -moz-border-radius: 50%;
    -ms-border-radius: 50%;
    border-radius: 50%;
    animation-name: stretch;
    animation-duration: 1s;
    animation-timing-function: ease-out;
    animation-delay: 0s;
    animation-direction: alternate;
    animation-iteration-count: infinite;
    animation-fill-mode: none;
    animation-play-state: running;
}

@keyframes stretch {
    0% {
        padding: 10px;
    }

    100% {
        padding: 0;
    }
}

.video-post .image-holder .hover-video a i {
    display: inline-block;
    width: 40px;
    height: 40px;
    background: #e74c3c;
    color: #fff;
    font-size: 14px;
    line-height: 40px;
    padding-left: 2px;
    -webkit-border-radius: 50%;
    -moz-border-radius: 50%;
    -ms-border-radius: 50%;
    border-radius: 50%;
}

.video-post .image-holder .hover-video a:hover {
    opacity: 0.8;
}

.video-post img {
    width: 100%;
    height: auto;
}

.video-post h2 {
    font-size: 18px;
    line-height: 24px;
    margin-bottom: 4px;
}

.video-post h2 a {
    color: #fff;
}

.video-post h2 a:hover {
    color: #fff;
}

.article-post .image-holder {
    margin-bottom: 15px;
    overflow: hidden;
}

.article-post .image-holder:hover img {
    animation-name: imagemove;
    animation-duration: 3s;
    animation-timing-function: ease-out;
    animation-delay: 0s;
    animation-direction: alternate;
    animation-iteration-count: infinite;
    animation-fill-mode: none;
    animation-play-state: running;
}

@keyframes imagemove {
    0% {
        -webkit-transform: rotate(0) scale(1);
        -moz-transform: rotate(0) scale(1);
        -ms-transform: rotate(0) scale(1);
        -o-transform: rotate(0) scale(1);
        transform: rotate(0) scale(1);
    }

    100% {
        -webkit-transform: rotate(5deg) scale(1.1);
        -moz-transform: rotate(5deg) scale(1.1);
        -ms-transform: rotate(5deg) scale(1.1);
        -o-transform: rotate(5deg) scale(1.1);
        transform: rotate(5deg) scale(1.1);
    }
}

.article-post {
    overflow: hidden;
    border-radius: 0.5rem;
}

.article-post img {
    width: 100%;
    height: 100%;
    border-radius: 0.5rem;

}

.article-post h2 {
    margin-bottom: 4px;
    padding-top: 15px;
    font-size: 24px;
    line-height: 30px;
}

.article-post h2 a {
    color: #333;
}

.article-post h2 a:hover {
    color: #fd6f63;
}

.article-post p {
    margin-top: 15px;
    margin-bottom: 0;
    font-size: 15px;
    color: #546078;
}

.article-post2 .image-holder {
    overflow: hidden;
}

.article-post2 .image-holder:hover img {
    animation-name: imagemove;
    animation-duration: 3s;
    animation-timing-function: ease-out;
    animation-delay: 0s;
    animation-direction: alternate;
    animation-iteration-count: infinite;
    animation-fill-mode: none;
    animation-play-state: running;
}

@keyframes imagemove {
    0% {
        -webkit-transform: rotate(0) scale(1);
        -moz-transform: rotate(0) scale(1);
        -ms-transform: rotate(0) scale(1);
        -o-transform: rotate(0) scale(1);
        transform: rotate(0) scale(1);
    }

    100% {
        -webkit-transform: rotate(5deg) scale(1.1);
        -moz-transform: rotate(5deg) scale(1.1);
        -ms-transform: rotate(5deg) scale(1.1);
        -o-transform: rotate(5deg) scale(1.1);
        transform: rotate(5deg) scale(1.1);
    }
}

.article-post2 img {
    width: 100%;
    height: auto;
}

a.text-link {
    color: #fd6f63;
    font-size: 14px;
    font-weight: 400;
    font-family: "Roboto", sans-serif;
    text-transform: capitalize;
    letter-spacing: 0.5px;
}

.article-post2 a.text-link {
    margin-top: 15px;
    margin-bottom: 10px;
}

.article-post2 h2 {
    margin-bottom: 4px;
}

.article-post2 h2 a {
    color: #333;
}

.article-post2 h2 a:hover {
    color: #e74c3c;
}

.article-post2 p {
    margin-top: 15px;
    margin-bottom: 0;
}

ul.posts-list>li {
    display: block;
    margin-bottom: 28px;
}

ul.posts-list>li a.text-link {
    margin-bottom: 0;
}

ul.posts-list>li h2 {
    font-size: 16px;
    font-weight: 400;
    line-height: 24px;
}

ul.posts-list>li h2 a {
    color: #333;
}

ul.posts-list>li h2 a:hover {
    color: #e74c3c;
}

section.top-images-section {
    /* background-color: #fafafa; */
    padding: 20px 0 55px;
}

section.top-images-section .news-post {
    margin: 30px 0;
    border-radius: 1.5rem;
    height: 400px;
}

section.top-images-section .news-post img {
    height: 100%;
    object-fit: cover;
}

section.fresh-section {
    padding: 45px 0 0;
}

section.fresh-section .fresh-box {
    padding-bottom: 50px;
    margin-left: -15px;
    margin-right: -15px;
}

section.fresh-section .fresh-box .item {
    padding: 0 15px;
}

section.fresh-section .fresh-box .owl-controls {
    margin-top: 0px;
    z-index: 999;
}

section.fresh-section .fresh-box .owl-pagination {
    margin-top: 30px;
}

section.fresh-section .fresh-box .owl-theme .owl-controls .owl-page span {
    width: 14px;
    height: 14px;
    margin: 0 2px;
    background: transparent;
    border: 1px solid transparent;
    position: relative;
}

section.fresh-section .fresh-box .owl-theme .owl-controls .owl-page span:before {
    content: '';
    position: absolute;
    top: 4px;
    left: 4px;
    right: 4px;
    bottom: 4px;
    background-color: #cccccc;
    -webkit-border-radius: 50%;
    -moz-border-radius: 50%;
    -ms-border-radius: 50%;
    border-radius: 50%;
}

section.fresh-section .fresh-box .owl-theme .owl-controls .owl-page.active span {
    border: 1px solid #cdcdcd;
}

section.fresh-section .fresh-box .owl-theme .owl-controls .owl-page.active span:before {
    background-color: #666666;
}

section.fresh-section .fresh-box .owl-buttons {
    display: block;
    position: absolute;
    top: 82px;
    left: 0;
    width: 100%;
    height: 0;
}

section.fresh-section .fresh-box .owl-buttons div {
    background: transparent;
    margin: 0;
    padding: 0;
    color: #fff;
    transition: all 0.2s ease-in-out;
    -moz-transition: all 0.2s ease-in-out;
    -webkit-transition: all 0.2s ease-in-out;
    -o-transition: all 0.2s ease-in-out;
    width: 40px;
    height: 40px;
    background-color: rgba(255, 255, 255, 0.25);
    -webkit-border-radius: 50%;
    -moz-border-radius: 50%;
    -ms-border-radius: 50%;
    border-radius: 50%;
    text-align: center;
    line-height: 40px;
}

section.fresh-section .fresh-box .owl-nav div:hover {
    background-color: #e74c3c;
}

section.fresh-section .fresh-box .owl-nav .owl-prev {
    float: left;
    margin-left: 35px;
}

section.fresh-section .fresh-box .owl-nav .owl-next {
    float: right;
    margin-right: 35px;
}

section.fresh-section.on-trend-mode {
    background-color: #fafafa;
}

section.fresh-section.on-trend-mode .fresh-box .owl-nav {
    display: none;
}

section.fresh-section2 {
    padding: 30px;
}

section.fresh-section2 .news-post {
    margin-bottom: 30px;
}

section.fresh-section2 a.button-one {
    margin-bottom: 20px;
}

section.trending-section {
    padding: 50px 0;
}

section.video-section {
    padding: 50px 0;
    /* background: #111 url('../../assets/images/ban2.jpg') center center no-repeat; */
    background-size: cover;
}

@media all and (-webkit-min-device-pixel-ratio: 1.5),
all and (-o-min-device-pixel-ratio: 3 / 2),
all and (min--moz-device-pixel-ratio: 1.5),
all and (min-device-pixel-ratio: 1.5) {
    section.video-section {
        /* background: #111 url(../upload/banners/ban2@2x.jpg) center center no-repeat; */
        background-size: cover;
    }
}

@media (-webkit-min-device-pixel-ratio: 2),
(min-resolution: 192dpi) {
    section.video-section {
        /* background: #111 url(../upload/banners/ban2@2x.jpg) center center no-repeat; */
        background-size: cover;
    }
}

section.video-section .video-box {
    margin-left: -15px;
    margin-right: -15px;
}

section.video-section .video-box .item {
    padding: 0 15px;
}

section.blog-section {
    padding: 50px 0;
}

section.blog-section .blog-box .title-section h1 {
    padding-bottom: 16px;
    border-bottom: 1px solid #ebebeb;
    margin-bottom: 26px;
}

section.blog-section .blog-box .iso-call {
    margin: -15px;
}

section.blog-section .blog-box .iso-call .item {
    padding: 15px;
    width: 50%;
    margin-bottom: 10px;
}

section.blog-section .blog-box.list-style .news-post {
    margin-bottom: 30px;
}

section.blog-section .advertise-box {
    margin-bottom: 50px;
    padding-top: 20px;
}

section.blog-section .advertise-box img {
    width: 100%;
    height: auto;
}

section.latest-section {
    padding: 50px 0;
}

section.latest-section .latest-box {
    margin: -15px;
}

section.latest-section .latest-box .item {
    width: 33.33333%;
    padding: 15px;
}

section.latest-section .center-button {
    margin-top: 20px;
}

section.advertise-section {
    padding: 40px 0 0;
}

section.advertise-section img {
    width: 100%;
    height: auto;
}

section.image-posts-section {
    padding-top: 30px;
}

section.image-posts-section .news-post .hover-post {
    bottom: 0;
    top: 0;
    display: flex;
    flex-direction: column;
    justify-content: center;
    text-align: center;
    align-items: center;
}

section.subscribe-section {
    padding-bottom: 50px;
}

section.subscribe-section .subscribe-box {
    padding: 40px 30px 10px;
    text-align: center;
    /* background: #111 url('../../assets/images/ban1.jpg') center center no-repeat; */
    background-size: cover;
}

@media all and (-webkit-min-device-pixel-ratio: 1.5),
all and (-o-min-device-pixel-ratio: 3 / 2),
all and (min--moz-device-pixel-ratio: 1.5),
all and (min-device-pixel-ratio: 1.5) {
    section.subscribe-section .subscribe-box {
        /* background: #111 url(../upload/banners/ban1@2x.jpg) center center no-repeat; */
        background-size: cover;
    }
}

@media (-webkit-min-device-pixel-ratio: 2),
(min-resolution: 192dpi) {
    section.subscribe-section .subscribe-box {
        /* background: #111 url(../upload/banners/ban1@2x.jpg) center center no-repeat; */
        background-size: cover;
    }
}

section.subscribe-section .subscribe-box .subscribe-title {
    margin-bottom: 20px;
}

section.subscribe-section .subscribe-box .subscribe-title h2 {
    font-size: 18px;
    margin-bottom: 3px;
}

section.subscribe-section .subscribe-box .subscribe-title p {
    color: #999;
    margin-bottom: 0;
}

section.subscribe-section .subscribe-box form {
    margin: 0;
}

section.subscribe-section .subscribe-box form input[type="text"] {
    width: 400px;
    max-width: 100%;
    padding: 13px 20px;
    background: #fff;
    border: 1px solid #eee;
    -webkit-border-radius: 0;
    -moz-border-radius: 0;
    -ms-border-radius: 0;
    border-radius: 0;
    margin: 0 0 30px;
    color: #999;
    font-size: 13px;
    font-family: Roboto, sans-serif;
    font-weight: 400;
    outline: 0;
}

section.subscribe-section .subscribe-box form button {
    background: #e74c3c;
    width: 170px;
    color: #fff;
    font-size: 13px;
    font-family: Roboto, sans-serif;
    font-weight: 400;
    text-transform: uppercase;
    border: 1px solid #e74c3c;
    outline: 0;
    -webkit-border-radius: 0;
    -moz-border-radius: 0;
    -ms-border-radius: 0;
    border-radius: 0;
    padding: 13px 10px;
    margin: 0;
    margin-left: -6px;
    cursor: pointer;
    transition: all 0.2s ease-in-out;
    -moz-transition: all 0.2s ease-in-out;
    -webkit-transition: all 0.2s ease-in-out;
    -o-transition: all 0.2s ease-in-out;
}

section.subscribe-section .subscribe-box form button:hover {
    opacity: 0.9;
}

section.instagram-section .instagram-box {
    padding: 50px 0;
}

section.instagram-section .title-section {
    margin-bottom: 30px;
}

section.instagram-section .title-section h1 {
    margin-bottom: 0;
    line-height: 24px;
}

section.instagram-section .title-section a {
    font-size: 12px;
    color: #999;
    font-family: Roboto, sans-serif;
}

section.instagram-section .title-section a:hover {
    color: #e74c3c;
}

section.instagram-section .insta-list {
    overflow: hidden;
    margin: 0 -5px;
}

section.instagram-section .insta-list li {
    display: block;
    float: left;
    width: 20%;
    padding: 0 5px;
}

section.instagram-section .insta-list li img {
    width: 100%;
    height: auto;
}

section.instagram-section .insta-list li a:hover {
    opacity: 0.7;
}

.sidebar {
    padding-left: 20px;
}

.sidebar .widget {
    margin-bottom: 15px;
    padding: 20px 20px;
    background-color: #fafafa;
}

.sidebar .widget>h2 {
    font-size: 18px;
    padding-bottom: 18px;
    margin-bottom: 20px;
    line-height: 22px;
    border-bottom: 1px solid #ebebeb;
    color: #000;
}

.sidebar .widget>h2 a {
    color: #999;
    font-size: 12px;
    font-family: Roboto, sans-serif;
}

.sidebar .widget.social-widget {
    background-color: transparent;
    padding: 0;
}

.sidebar .widget.social-widget>h2 {
    padding-bottom: 0;
    border-bottom: none;
    margin-bottom: 18px;
}

.sidebar .widget.social-widget ul.social-list {
    display: flex;
    text-align: center;
}

.sidebar .widget.social-widget ul.social-list li {
    list-style: none;
    padding-right: 1px;
    flex: 1;
}

.sidebar .widget.social-widget ul.social-list li a {
    background-color: #fafafa;
    display: block;
    padding: 25px 4px;
    color: #333;
    font-size: 12px;
    font-family: Roboto, sans-serif;
    font-weight: 400;
    line-height: 20px;
}


.sidebar .widget.social-widget ul.social-list li a i {
    display: block;
    font-size: 20px;
    margin-bottom: 4px;
}

.sidebar .widget.social-widget ul.social-list li a span {
    display: block;
    color: #ccc;
    margin-bottom: 0;
}

.sidebar .widget.social-widget ul.social-list li a:hover {
    background-color: var(--brand-07);
    color: black;
}

.sidebar .widget.features-widget {
    padding: 0;
    background-color: transparent;
}

.sidebar .widget.features-widget .image-post .hover-post {
    bottom: 0;
    top: 0;
    text-align: center;
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.sidebar .widget ul.list-posts>li {
    display: block;
    padding-bottom: 20px;
    margin-bottom: 18px;
    border-bottom: 1px solid #ebebeb;

}

.sidebar .widget ul.list-posts>li.list_ {
    display: flex;

}

.sidebar .widget ul.list-posts>li.list_ .img-thumb {
    width: 60px;
}

.sidebar .widget ul.list-posts>li.list_ .img-thumb img {
    width: 60px;
    height: 60px;
    border-radius: 10px;
    object-fit: cover;
}
.sidebar .widget ul.list-posts>li.list_ .Latest_content{
    width: 85%;
    margin-left: 15px;
}
.sidebar .widget ul.list-posts>li:last-child {
    border-bottom: none;
    padding-bottom: 0;
    margin-bottom: 0;
}

.sidebar .widget ul.list-posts>li h2 {
    font-size: 16px;
    font-family: Roboto, sans-serif;
    font-weight: 400;
    margin-bottom: 6px;
    line-height: 24px;
}

.sidebar .widget ul.list-posts>li h2 a {
    color: #333;
}

.sidebar .widget ul.list-posts>li h2 a:hover {
    color: #e74c3c;
}

.sidebar .widget.instagram-widget {
    padding-top: 0;
    padding-bottom: 0;
    background: 0 0;
}

.sidebar .widget.instagram-widget h2 {
    padding-bottom: 0;
    border-bottom: none;
}

.sidebar .widget ul.category-list li {
    display: block;
    margin-bottom: 15px;
}

.sidebar .widget ul.category-list li a {
    display: block;
    font-size: 16px;
    font-family: Roboto, sans-serif;
    font-weight: 400;
    color: #666;
    line-height: 24px;
}

.sidebar .widget ul.category-list li a span {
    color: #e74c3c;
    float: right;
    font-size: 10px;
}

.sidebar .widget ul.category-list li a:hover {
    color: #e74c3c;
}

.sidebar .widget ul.category-list li:last-child {
    margin-bottom: 0;
}

.sidebar .widget ul.insta-list {
    margin-bottom: 7px;
}

.sidebar .widget ul.insta-list li {
    display: inline-block;
    margin-right: 10px;
    margin-bottom: 13px;
}

.sidebar .widget ul.insta-list li a img {
    max-width: 70px;
    height: auto;
}

.sidebar .widget ul.insta-list li a:hover {
    opacity: 0.7;
}

.sidebar .subscribe-widget p {
    font-size: 13px;
    color: #999;
    margin-bottom: 20px;
}

.sidebar .subscribe-widget form input[type="text"] {
    width: 100%;
    display: block;
    border: none;
    background-color: #fafafa;
    padding: 12px 20px;
    font-size: 13px;
    color: #999;
    margin-bottom: 20px;
    outline: 0;
}

.sidebar .subscribe-widget form input[type="submit"] {
    width: 100%;
    display: block;
    outline: 0;
    color: #e74c3c;
    font-size: 13px;
    font-family: Roboto, sans-serif;
    padding: 13px 50px;
    background: #fff;
    font-weight: 400;
    border: 1px solid #ebebeb;
    transition: all 0.2s ease-in-out;
    -moz-transition: all 0.2s ease-in-out;
    -webkit-transition: all 0.2s ease-in-out;
    -o-transition: all 0.2s ease-in-out;
    text-transform: uppercase;
    margin: 0;
}

.sidebar .subscribe-widget form input[type="submit"]:hover {
    background: #e74c3c;
    border-color: #e74c3c;
    color: #fff;
}

.sidebar .subscribe-widget2 {
    /* background: #111 url('../../assets/images/ban4.jpg') center center no-repeat; */
    background-size: cover;
}

@media all and (-webkit-min-device-pixel-ratio: 1.5),
all and (-o-min-device-pixel-ratio: 3 / 2),
all and (min--moz-device-pixel-ratio: 1.5),
all and (min-device-pixel-ratio: 1.5) {
    .sidebar .subscribe-widget2 {
        /* background: #111 url(../upload/banners/ban4@2x.jpg) center center no-repeat; */
        background-size: cover;
    }
}

@media (-webkit-min-device-pixel-ratio: 2),
(min-resolution: 192dpi) {
    .sidebar .subscribe-widget2 {
        /* background: #111 url(../upload/banners/ban4@2x.jpg) center center no-repeat; */
        background-size: cover;
    }
}

.sidebar .subscribe-widget2 h2 {
    padding-bottom: 0;
    border-bottom: none;
    margin-bottom: 5px;
}

.sidebar .subscribe-widget2 p {
    font-size: 13px;
    color: #999;
    margin-bottom: 20px;
}

.sidebar .subscribe-widget2 input[type="text"] {
    width: 100%;
    display: block;
    border: 1px solid #eee;
    background-color: #fff;
    padding: 12px 20px;
    font-size: 13px;
    color: #999;
    margin-bottom: 20px;
    outline: 0;
}

.sidebar .subscribe-widget2 button {
    width: 100%;
    display: block;
    outline: 0;
    color: #e74c3c;
    font-size: 13px;
    font-family: Roboto, sans-serif;
    cursor: pointer;
    padding: 13px 50px;
    background: #fff;
    font-weight: 400;
    border: 1px solid #ebebeb;
    transition: all 0.2s ease-in-out;
    -moz-transition: all 0.2s ease-in-out;
    -webkit-transition: all 0.2s ease-in-out;
    -o-transition: all 0.2s ease-in-out;
    text-transform: uppercase;
    margin: 0;
}

.sidebar .subscribe-widget2 button:hover {
    background: #e74c3c;
    border-color: #e74c3c;
    color: #fff;
}

.sidebar .popular-video-widget .video-post h2 a {
    color: #333;
}

.sidebar .popular-video-widget .video-post h2 a:hover {
    color: #e74c3c;
}

.single-post .single-post-content>img {
    width: 100%;
    height: auto;
    margin-bottom: 20px;
}

.single-post .single-post-content .post-content {
    display: flex;
}

.single-post .single-post-content .post-content .post-social {
    text-align: center;
    width: 70px;
    margin-right: 30px;
    position: sticky;
    top: 40px;
    height: 100%;
}

.single-post .single-post-content .post-content .post-social span {
    display: inline-block;
    color: #666;
    font-size: 10px;
    font-family: Roboto, sans-serif;
    text-transform: uppercase;
    margin-bottom: 15px;
}

.single-post .single-post-content .post-content .post-social ul.share-post li {
    display: block;
    margin-bottom: 7px;
}

.single-post .single-post-content .post-content .post-social ul.share-post li a {
    width: 40px;
    height: 40px;
    color: #333;
    font-size: 14px;
    border: 1px solid #eee;
    line-height: 38px;
    -webkit-border-radius: 50%;
    -moz-border-radius: 50%;
    -ms-border-radius: 50%;
    border-radius: 50%;
}

.single-post .single-post-content .post-content .post-social ul.share-post li a:hover {
    color: #fff;
    border-color: transparent;
}

.single-post .single-post-content .post-content .post-social ul.share-post li a.facebook:hover {
    background-color: #483ce7;
}

.single-post .single-post-content .post-content .post-social ul.share-post li a.twitter:hover {
    background-color: #5199f2;
}

.single-post .single-post-content .post-content .post-social ul.share-post li a.pinterest:hover {
    background-color: #dc4343;
}

.single-post .single-post-content .post-content .post-content-text h1 {
    font-size: 24px;
    margin-bottom: 4px;
}

.single-post .single-post-content .post-content .post-content-text ul.post-tags {
    margin-bottom: 25px;
}

.single-post .single-post-content .post-content .post-content-text p,
.single-post .single-post-content .post-content .post-content-text p span,
.single-post .single-post-content .post-content .post-content-text ul li span {
    margin-bottom: 25px;
    font-size: 16px;
    line-height: 30px;
    background-color: transparent !important;
    font-size: 16px !important;

}


.single-post .single-post-content .post-content .post-content-text p span {
    background-color: transparent !important;
}

.single-post .single-post-content .post-content .post-content-text p a {
    color: #333;
    text-decoration: underline !important;
}

.single-post .single-post-content .post-content .post-content-text p a:hover {
    color: #e74c3c;
}

.single-post .single-post-content .post-content .post-content-text h2 {
    padding: 40px 22px;
    border-top: 1px solid #ebebeb;
    border-bottom: 1px solid #ebebeb;
    text-align: center;
    color: #333;
    font-size: 24px;
    line-height: 36px;
    font-weight: 400;
    font-family: Roboto, sans-serif;
    margin: 40px 0;
}

.single-post .single-post-content .post-content .post-content-text h3 {
    font-size: 18px;
    font-weight: 700;
    margin-bottom: 30px;
}

.single-post .single-post-content .post-content .post-content-text .image-alt-box {
    display: flex;
    justify-content: space-between;
    text-align: center;
}

.single-post .single-post-content .post-content .post-content-text .image-alt-box .image-holder {
    margin: 0 0 25px;
}

.single-post .single-post-content .post-content .post-content-text .image-alt-box .image-holder img {
    display: block;
    width: 325px;
    margin-bottom: 5px;
}

.single-post .single-post-content .post-content .post-content-text .image-alt-box .image-holder span {
    color: #ccc;
    font-size: 13px;
    font-family: Roboto, sans-serif;
    font-style: italic;
}

.single-post .single-post-content .post-content .post-content-text .share-tags-box {
    overflow: hidden;
    margin-bottom: 40px;
}

.single-post .single-post-content .post-content .post-content-text .share-tags-box ul.tags li {
    display: inline-block;
    margin-right: 6px;
}

.single-post .single-post-content .post-content .post-content-text .share-tags-box ul.tags li a {
    color: #333;
    font-size: 13px;
    font-family: Roboto, sans-serif;
    padding: 6px 10px;
    border: 1px solid #ebebeb;
    border-radius: 50px;
}

.single-post .single-post-content .post-content .post-content-text .share-tags-box ul.tags li a:hover {
    border-color: transparent;
    color: #fff;
    background-color: #e74c3c;
}

.single-post .single-post-content .post-content .post-content-text .share-tags-box ul.shares-likes {
    margin-top: 20px;
}

.single-post .single-post-content .post-content .post-content-text .share-tags-box ul.shares-likes li {
    display: inline-block;
    margin-right: 6px;
}

.single-post .single-post-content .post-content .post-content-text .share-tags-box ul.shares-likes li a {
    color: #333;
    font-size: 13px;
    font-family: Roboto, sans-serif;
    padding: 6px 17px;
    border: 1px solid #ebebeb;
    -webkit-border-radius: 16px;
    -moz-border-radius: 16px;
    -ms-border-radius: 16px;
    border-radius: 16px;
}

.single-post .single-post-content .post-content .post-content-text .share-tags-box ul.shares-likes li a.likes i {
    color: #e74c3c;
    margin: 0 4px;
}

.single-post .single-post-content .post-content .post-content-text .share-tags-box ul.shares-likes li a.facebook i {
    color: #483ce7;
    margin-right: 4px;
}

.single-post .single-post-content .post-content .post-content-text .share-tags-box ul.shares-likes li a.twitter i {
    color: #5199f2;
    margin-right: 4px;
}

.single-post .single-post-content .post-content .post-content-text .share-tags-box ul.shares-likes li a.pinterest i {
    color: #dc4343;
    margin-right: 4px;
}

.single-post .single-post-content .post-content .post-content-text .share-tags-box ul.shares-likes li a:hover i {
    color: #fff !important;
}

.single-post .single-post-content .post-content .post-content-text .share-tags-box ul.shares-likes li a.likes:hover {
    border-color: transparent;
    color: #fff;
    background-color: #e74c3c;
}

.single-post .single-post-content .post-content .post-content-text .share-tags-box ul.shares-likes li a.facebook:hover {
    border-color: transparent;
    color: #fff;
    background-color: #483ce7;
}

.single-post .single-post-content .post-content .post-content-text .share-tags-box ul.shares-likes li a.twitter:hover {
    border-color: transparent;
    color: #fff;
    background-color: #5199f2;
}

.single-post .single-post-content .post-content .post-content-text .share-tags-box ul.shares-likes li a.pinterest:hover {
    border-color: transparent;
    color: #fff;
    background-color: #dc4343;
}

.single-post .single-post-content .prev-next-box {
    padding: 30px 0;
    margin-bottom: 20px;
    display: flex;
    justify-content: space-between;
    border-top: 1px solid #ebebeb;
}

.single-post .single-post-content .prev-next-box .prev-box {
    padding-right: 20px;
    width: 50%;
}

.single-post .single-post-content .prev-next-box .next-box {
    padding-left: 20px;
    text-align: right;
    width: 50%;
}

.single-post .single-post-content .prev-next-box a.text-link {
    color: #999;
    margin-bottom: 6px;
}

.single-post .single-post-content .prev-next-box a.text-link i {
    margin-right: 5px;
}

.single-post .single-post-content .prev-next-box a.text-link.next-link {
    float: right;
}

.single-post .single-post-content .prev-next-box a.text-link.next-link i {
    margin-left: 5px;
    margin-right: 0;
}

.single-post .single-post-content .prev-next-box a.text-link:hover {
    color: #e74c3c;
}

.single-post .single-post-content .prev-next-box h2 {
    font-size: 14px;
    font-weight: 400;
    line-height: 22px;
}

.single-post .single-post-content .prev-next-box h2 a {
    color: #333;
}

.single-post .single-post-content .prev-next-box h2 a:hover {
    color: #e74c3c;
}

.single-post .single-post-content .related-box {
    padding: 30px;
    background-color: #fafafa;
}

.single-post .single-post-content .related-box>h2 {
    font-size: 18px;
    margin-bottom: 30px;
    padding-bottom: 20px;
    border-bottom: 1px solid #ebebeb;
}

.single-post .single-post-content .related-box .news-post h2 {
    font-size: 15px;
    font-weight: 400;
}

.comments h2.comments-title {
    font-size: 18px;
    padding-bottom: 20px;
    margin-bottom: 30px;
    border-bottom: 1px solid #ebebeb;
}

.comments__list-item {
    list-style: none;
    margin-bottom: 1.875rem;
    padding-top: 1.875rem;
    border-top: 1px solid #eee;
}

.comments__list-item:first-child {
    padding-top: 0;
    border-top: none;
}

.comments__list-item-image {
    float: left;
    width: 3.125rem;
    height: 3.125rem;
    margin-right: 1.25rem;
    -webkit-border-radius: 3px;
    -moz-border-radius: 3px;
    -ms-border-radius: 3px;
    border-radius: 3px;
}

.comments__list-item-content {
    overflow: hidden;
}

.comments__list-item-title {
    color: #333;
    font-size: 0.9375rem;
    font-weight: 400;
    line-height: 1.25rem;
    margin: 0;
}

.comments__list-item-date {
    display: inline-block;
    color: #ccc;
    font-size: 0.875rem;
    font-weight: 400;
    line-height: 1.25rem;
    margin: 0 0 1rem;
}

.comments__list-item-reply {
    float: right;
    padding: 0.25rem 0.5rem;
    color: #999;
    margin: -5px 0 20px;
    font-size: 0.75rem;
    font-weight: 400;
    font-family: Roboto, sans-serif;
    border: 1px solid #ebebeb;
    -webkit-border-radius: 1.25rem;
    -moz-border-radius: 1.25rem;
    -ms-border-radius: 1.25rem;
    border-radius: 1.25rem;
}

.comments__list-item-reply i {
    font-size: 0.75rem;
    float: left;
    margin-right: 0.25rem;
    margin-top: 0.125rem;
}

.comments__list-item-reply:hover {
    color: #e74c3c;
}

.contact-form {
    padding: 1.875rem;
    background: #fafafa;
    margin-bottom: 1.875rem;
    -webkit-border-radius: 3px;
    -moz-border-radius: 3px;
    -ms-border-radius: 3px;
    border-radius: 3px;
}

.contact-form h2 {
    font-size: 18px;
    padding-bottom: 20px;
    margin-bottom: 30px;
    border-bottom: 1px solid #ebebeb;
}

.contact-form__input-text,
.contact-form__textarea {
    display: block;
    width: 100%;
    padding: 0.625rem 1.25rem;
    color: #999;
    font-size: 0.875rem;
    font-weight: 400;
    background: #fff;
    outline: 0;
    border: 1px solid #ebebeb;
    margin: 0 0 1.875rem;
    transition: all 0.2s ease-in-out;
    -moz-transition: all 0.2s ease-in-out;
    -webkit-transition: all 0.2s ease-in-out;
    -o-transition: all 0.2s ease-in-out;
}

.contact-form__input-text:hover,
.contact-form__textarea:hover {
    border-color: #e74c3c;
}

.contact-form__textarea {
    height: 6.25rem;
}

.contact-form__submit {
    color: #e74c3c;
    font-size: 13px;
    font-family: Roboto, sans-serif;
    padding: 13px 50px;
    background: #fff;
    font-weight: 400;
    border: 1px solid #ebebeb;
    transition: all 0.2s ease-in-out;
    -moz-transition: all 0.2s ease-in-out;
    -webkit-transition: all 0.2s ease-in-out;
    -o-transition: all 0.2s ease-in-out;
    text-transform: uppercase;
    outline: 0;
    text-align: center;
    width: 100%;
    cursor: pointer;
    margin: 0;
}

.contact-form__submit:hover {
    background: #e74c3c;
    border-color: #e74c3c;
    color: #fff;
}

.blog-section,
.top-images-section {
    margin-top: 30px;
}

.blog-section .single-post-image {
    margin-top: -50px;
    margin-bottom: 40px;
}

.blog-section .single-post-image .hover-post {
    bottom: 0;
    top: 0;
    display: flex;
    flex-direction: column;
    justify-content: center;
    text-align: center;
    align-items: center;
}

.blog-section .single-post-image h2 {
    color: #fff;
}

.blog-section .single-post-image img {
    animation: none;
}

section.contact-section {
    padding: 50px 0;
}

section.contact-section div.contact-box p {
    color: #333;
    font-size: 16px;
    margin-bottom: 20px;
}

section.contact-section #contact-form {
    margin: 0;
    text-align: center;
}

section.contact-section #contact-form input[type="text"],
section.contact-section #contact-form textarea {
    width: 100%;
    padding: 13px 20px;
    background: #fafafa;
    color: #999;
    font-size: 13px;
    font-family: Roboto, sans-serif;
    font-weight: 400;
    outline: 0;
    border: 1px solid #e1e1e1;
    margin: 0 0 30px;
    transition: all 0.2s ease-in-out;
    -moz-transition: all 0.2s ease-in-out;
    -webkit-transition: all 0.2s ease-in-out;
    -o-transition: all 0.2s ease-in-out;
}

section.contact-section #contact-form input[type="text"]:focus,
section.contact-section #contact-form textarea:focus {
    border: 1px solid #e74c3c;
}

section.contact-section #contact-form textarea {
    height: 106px;
}

section.contact-section #contact-form input[type="submit"] {
    color: #e74c3c;
    width: 100%;
    text-align: center;
    outline: 0;
    font-size: 13px;
    font-family: Roboto, sans-serif;
    padding: 13px 50px;
    background: #fff;
    font-weight: 400;
    cursor: pointer;
    border: 1px solid #ebebeb;
    transition: all 0.2s ease-in-out;
    -moz-transition: all 0.2s ease-in-out;
    -webkit-transition: all 0.2s ease-in-out;
    -o-transition: all 0.2s ease-in-out;
    text-transform: uppercase;
    margin: 0;
}

section.contact-section #contact-form input[type="submit"]:hover {
    background: #e74c3c;
    border-color: #e74c3c;
    color: #fff;
}

.blog_one_slider .owl-nav {
    top: 120px !important;
    position: absolute;
    width: 100%;
    margin-top: 0 !important;
}

.blog_one_slider .owl-nav .owl-prev,
.blog_one_slider .owl-nav .owl-next {
    background: transparent;
    margin: 0;
    padding: 0;
    color: #fff !important;
    transition: all 0.2s ease-in-out;
    -moz-transition: all 0.2s ease-in-out;
    -webkit-transition: all 0.2s ease-in-out;
    -o-transition: all 0.2s ease-in-out;
    width: 40px;
    height: 40px;
    background-color: rgba(255, 255, 255, 0.25) !important;
    -webkit-border-radius: 50% !important;
    -moz-border-radius: 50% !important;
    -ms-border-radius: 50% !important;
    border-radius: 50% !important;
    text-align: center;
    line-height: 30px !important;
    display: flex;

}

.blog_one_slider .owl-nav .owl-prev span,
.blog_one_slider .owl-nav .owl-next span {
    font-size: 40px;
    margin-bottom: 5px;
}

.blog_one_slider .swiper-button-next {
    margin-right: 30px;
}

.blog_one_slider .swiper-button-prev {
    margin-left: 30px;
}

.blog_one_slider .swiper-button-next::after,
.blog_one_slider .swiper-button-prev::after {
    font-size: 20px !important;
}

.blog_one_slider .owl-dots {
    margin-top: 30px;
}

.owl-dots .owl-dot span {
    background-color: transparent !important;
    width: 14px !important;
    height: 14px !important;
    position: relative;
    border: 1px solid transparent;
    margin: 0 2px !important;
}

.owl-dots .owl-dot span::after {
    content: '';
    position: absolute;
    top: 4px;
    left: 4px;
    right: 4px;
    bottom: 4px;
    background-color: #b9aeae;
    -webkit-border-radius: 50%;
    -moz-border-radius: 50%;
    -ms-border-radius: 50%;
    border-radius: 50%;
}

.owl-dots .owl-dot.active span {
    border: 1px solid #cdcdcd !important;
}

.owl-dots .owl-dot.active span::after {
    background-color: #666666;
}

.blog_one_slider {
    padding-bottom: 0;
}

.blog_two_slider .owl-dots {
    top: 25px;
    position: absolute;
    right: 25px;
}

.blog_two_slider_white_dots .owl-dots .owl-dot span {
    background-color: transparent !important;
    width: 14px !important;
    height: 14px !important;
    position: relative;
    border: 1px solid transparent;
    margin: 0 2px !important;
}

.blog_two_slider_white_dots .owl-dots .owl-dot span::after {
    content: '';
    position: absolute;
    top: 4px;
    left: 4px;
    right: 4px;
    bottom: 4px;
    background-color: #fff;
    -webkit-border-radius: 50%;
    -moz-border-radius: 50%;
    -ms-border-radius: 50%;
    border-radius: 50%;
}

.blog_two_slider_white_dots .owl-dots .owl-dot.active span {
    border: 1px solid #fff !important;
}

.blog_two_slider_white_dots .owl-dots .owl-dot.active span::after {
    background-color: #fff;
}

.blog_three_slider .owl-dots {
    margin-top: 35px;
}

.blog_four_slider .owl-dots {
    position: absolute;
    bottom: 30px;
    left: 50%;
    transform: translateX(-50%);
}

.portfolio-list {
    /* align-items: stretch; */
    /* display: flex; */
    /* height: 100% !important; */
}

.description_heading {
    padding-bottom: 0px !important;
}

.description_heading_t {
    padding-top: 24px;
    padding-bottom: 0;
}

.rlr-review-card {
    margin-bottom: 20px;
}

.trending_posts_section {
    height: 500px;
    border-radius: 1.5rem;
    background-attachment: fixed;
}

a.category-link {
    color: #fff;
    font-size: 10px;
    font-weight: 400;
    font-family: "Roboto", sans-serif;
    text-transform: uppercase;
    padding: 4px 13px;
    background-color: #fd6f63;
    letter-spacing: 0.5px;
}

.image_post_new {
    display: flex;
    justify-content: flex-end;
}

/* .image_post_new img{
    height: 100%;
    object-fit: cover;
} */
.row-cols-xl-2>* {
    margin-bottom: 40px !important;
}

.title_section_blog_list .row-cols-xl-2>* {
    margin-bottom: 70px !important;
}

.row-cols-xl-3>* {
    margin-bottom: 50px !important;
}

.image_post_home_pro {
    height: 500px;
    border-radius: .5rem;
    overflow: hidden;
}

.image_post_home_pro img {
    height: 100% !important;
    object-fit: cover;
}

.list_posts_tag a {
    padding: 0px 8px !important;
    border: 1px solid var(--base-color) !important;
    margin: 5px 5px;
    border-radius: 10px;
    transition: 0.5s;
    color: #fd6f63;
    font-size: 10px;
    font-weight: 400;
    font-family: "Roboto", sans-serif;
    text-transform: uppercase;
    letter-spacing: 0.5px;
}

.item_cat a {
    padding: 20px 8px;
    border: 1px solid #d5cfcf;
    /* margin: 5px 5px; */
    border-radius: 10px;
    transition: 0.5s;
    color: #fd6f63;
    font-size: 10px;
    font-weight: 400;
    font-family: "Roboto", sans-serif;
    text-transform: uppercase;
    letter-spacing: 0.5px;
    width: 100%;
    text-align: center;
    font-size: 14px;
}

.list_posts_tag a:hover {
    background-color: #fd6f63;
    color: #fff;
}

.text_link_ {
    position: relative;
    padding: 0 5px;
}

.text_link_::after {
    position: absolute;
    content: '';
    top: 24%;
    right: 0;
    width: 1px;
    height: 56%;
    background-color: #bec1c2;
    transform: translate(42%, 0);
}

.list_ {
    line-height: 20px;
}

.list_ .text_link_:last-child::after {
    display: none;
}

.sidebar_new {
    height: 100%;
}

.right_sidebar {
    position: sticky;
    top: 15px;
}

.tags_section {
    border: 1px solid #bec1c2;
    border-radius: 0.5rem;
    padding: 20px 10px 0;
    /* height: fit-content; */
}

.tags_section .widget {
    text-align: center;
}

.tags_section .widget h2 {
    font-size: 20px;
    color: #000;
    margin-bottom: 15px !important;
}

.tags_section ul {
    padding-left: 0;
}

.fresh_section2 h1 {
    padding-bottom: 10px;
    border-bottom: 1px solid #ebebeb;
    margin-bottom: 30px;
}

@media (max-width: 1199px) {
    .single-post .single-post-content .post-content .post-content-text .image-alt-box .image-holder img {
        width: 265px;
    }
}

@media (max-width: 991px) {
    .trending-box .owl-wrapper {
        margin-bottom: 30px;
    }

    .sidebar {
        padding-left: 0;
    }

    section.latest-section .latest-box .item {
        width: 50%;
    }

    .single-post .single-post-content .post-content .post-content-text .image-alt-box .image-holder img {
        width: 305px;
    }

    .blog-section .single-post-image>img {
        height: 350px;
        width: auto;
    }
}

@media (max-width: 767px) {
    ul.filter-list {
        text-align: left;
    }

    ul.filter-list li {
        margin-left: 0;
        margin-right: 10px;
    }

    section.subscribe-section .subscribe-box form input[type="text"] {
        width: 100%;
    }

    section.subscribe-section .subscribe-box form button {
        margin-bottom: 30px;
    }

    section.blog-section .blog-box .iso-call .item {
        width: 100%;
    }

    section.latest-section .latest-box .item {
        width: 100%;
    }

    section.image-posts-section .image-post {
        margin-bottom: 30px;
    }

    .single-post .single-post-content .post-content .post-content-text .image-alt-box {
        display: block;
    }

    .single-post .single-post-content .post-content .post-content-text .image-alt-box .image-holder img {
        width: 100%;
    }

    .single-post .single-post-content .post-content .post-content-text .share-tags-box ul.shares-likes,
    .single-post .single-post-content .post-content .post-content-text .share-tags-box ul.tags {
        float: none;
        text-align: left;
        margin-bottom: 10px;
    }

    .single-post .single-post-content .post-content .post-content-text .share-tags-box ul.shares-likes li,
    .single-post .single-post-content .post-content .post-content-text .share-tags-box ul.tags li {
        margin-left: 0;
        margin-right: 4px;
    }

    .single-post .single-post-content .related-box .news-post {
        margin-bottom: 30px;
    }

    .blog-section .single-post-image>img {
        height: 300px;
        width: auto;
    }
}

@media (max-width: 640px) {
    .blog-section .single-post-image>img {
        height: 250px;
        width: auto;
    }
}

@media (max-width: 400px) {
    section.instagram-section .insta-list li {
        width: 33.3333%;
        margin-bottom: 10px;
    }
}

.top_home_section {
    margin-top: 50px;
}

.image_post_blogdetail {
    border-radius: 0.5rem;

}

.image_post_blogdetail_one {
    height: 640px;
}

.image_post_blogdetail_one img {
    width: 100% !important;
    height: 100% !important;
    object-fit: cover;
}

.image_post_blogdetail_two {
    height: 329px;
}

.image_post_blogdetail_two img {
    height: 100% !important;
    object-fit: cover;
}

.standard_post_left_align .image-holder {
    height: 210px;
}

.standard_post_left_align .image-holder a {
    height: 100%;
    width: 100%;
}

.standard_post_left_align img {
    width: 100% !important;
    height: 100% !important;
    object-fit: cover;
}

ul.post-tags {
    margin: 0;
    padding: 0;
}


#container {
    overflow: hidden;
    opacity: 0;
}

a.category-link {
    color: #fff;
    font-size: 10px;
    font-weight: 400;
    font-family: Roboto, sans-serif;
    text-transform: uppercase;
    padding: 4px 13px;
    background-color: #fd6f63;
    letter-spacing: 0.5px;
}

a.text-link {

    font-size: 10px;
    font-weight: 400;
    font-family: Roboto, sans-serif;
    text-transform: uppercase;
    letter-spacing: 0.5px;
}

ul.post-tags li {
    display: inline-block;
    color: #999;
    font-family: Roboto, sans-serif;
    font-weight: 400;
    font-size: 13px;
    line-height: 20px;
    margin-right: 5px;
}

ul.post-tags li a {
    color: #999;
}

ul.post-tags li a:hover {
    color: #e74c3c;
    text-decoration: underline !important;
}

ul.post-tags li:before {
    content: "|";
    color: #999;
    font-family: Roboto, sans-serif;
    font-weight: 400;
    font-size: 14px;
    margin-right: 8px;
}

ul.post-tags li:first-child:before {
    display: none;
    margin-right: 0;
}

.image-post {
    position: relative;
    overflow: hidden;
}

.image-post img {
    width: 100%;
    height: auto;
    transition: all 0.6s ease-in-out;
    -moz-transition: all 0.6s ease-in-out;
    -webkit-transition: all 0.6s ease-in-out;
    -o-transition: all 0.6s ease-in-out;
}

.image-post:hover img {
    animation-name: imagemove;
    animation-duration: 3s;
    animation-timing-function: ease-out;
    animation-delay: 0s;
    animation-direction: alternate;
    animation-iteration-count: infinite;
    animation-fill-mode: none;
    animation-play-state: running;
}

@keyframes imagemove {
    0% {
        -webkit-transform: rotate(0) scale(1);
        -moz-transform: rotate(0) scale(1);
        -ms-transform: rotate(0) scale(1);
        -o-transform: rotate(0) scale(1);
        transform: rotate(0) scale(1);
    }

    100% {
        -webkit-transform: rotate(5deg) scale(1.1);
        -moz-transform: rotate(5deg) scale(1.1);
        -ms-transform: rotate(5deg) scale(1.1);
        -o-transform: rotate(5deg) scale(1.1);
        transform: rotate(5deg) scale(1.1);
    }
}

.image-post .hover-post {
    position: absolute;
    padding: 30px 26px;
    bottom: 0;
    left: 0;
    right: 0;
    background: linear-gradient(0deg, rgb(0 0 0 / 69%) 30%, rgb(0 14 0 / 0%) 72%);
}

.image-post .hover-post a.category-link {
    margin-bottom: 8px;
}

.image-post .hover-post h2 {
    margin-bottom: 5px;
    font-size: 24px;
}

.image-post .hover-post h2 a {
    color: #fff;
}

.image-post .hover-post ul.post-tags li {
    color: #fff;
}

.image-post .hover-post ul.post-tags li a {
    color: #fff;
}

.image-post .hover-post ul.post-tags li a:hover {
    color: #e74c3c;
}

.image-post .hover-post ul.post-tags li:before {
    color: #fff;
}

.standard-post {
    text-align: center;
}

.standard-post.left-align {
    text-align: left;
}

.standard-post .image-holder {
    margin-bottom: 14px;
    overflow: hidden;
}

.standard-post .image-holder:hover img {
    animation-name: imagemove;
    animation-duration: 3s;
    animation-timing-function: ease-out;
    animation-delay: 0s;
    animation-direction: alternate;
    animation-iteration-count: infinite;
    animation-fill-mode: none;
    animation-play-state: running;
}

@keyframes imagemove {
    0% {
        -webkit-transform: rotate(0) scale(1);
        -moz-transform: rotate(0) scale(1);
        -ms-transform: rotate(0) scale(1);
        -o-transform: rotate(0) scale(1);
        transform: rotate(0) scale(1);
    }

    100% {
        -webkit-transform: rotate(5deg) scale(1.1);
        -moz-transform: rotate(5deg) scale(1.1);
        -ms-transform: rotate(5deg) scale(1.1);
        -o-transform: rotate(5deg) scale(1.1);
        transform: rotate(5deg) scale(1.1);
    }
}

.standard-post img {
    width: 100%;
    height: auto;
    transition: all 0.6s ease-in-out;
    -moz-transition: all 0.6s ease-in-out;
    -webkit-transition: all 0.6s ease-in-out;
    -o-transition: all 0.6s ease-in-out;
}

.standard-post h2 {
    font-size: 18px;
    line-height: 24px;
    margin-bottom: 2px;
}

.standard-post h2 a {
    color: #333;
}

.standard-post h2 a:hover {
    color: #e74c3c;
}

@keyframes stretch {
    0% {
        padding: 10px;
    }

    100% {
        padding: 0;
    }
}

@keyframes imagemove {
    0% {
        -webkit-transform: rotate(0) scale(1);
        -moz-transform: rotate(0) scale(1);
        -ms-transform: rotate(0) scale(1);
        -o-transform: rotate(0) scale(1);
        transform: rotate(0) scale(1);
    }

    100% {
        -webkit-transform: rotate(5deg) scale(1.1);
        -moz-transform: rotate(5deg) scale(1.1);
        -ms-transform: rotate(5deg) scale(1.1);
        -o-transform: rotate(5deg) scale(1.1);
        transform: rotate(5deg) scale(1.1);
    }
}

@keyframes imagemove {
    0% {
        -webkit-transform: rotate(0) scale(1);
        -moz-transform: rotate(0) scale(1);
        -ms-transform: rotate(0) scale(1);
        -o-transform: rotate(0) scale(1);
        transform: rotate(0) scale(1);
    }

    100% {
        -webkit-transform: rotate(5deg) scale(1.1);
        -moz-transform: rotate(5deg) scale(1.1);
        -ms-transform: rotate(5deg) scale(1.1);
        -o-transform: rotate(5deg) scale(1.1);
        transform: rotate(5deg) scale(1.1);
    }
}

section.blog-section {
    padding: 50px 0;
}

section.top-home-section {
    padding: 30px 0;
}

section.top-home-section .top-home-box .news-post {
    margin-bottom: 30px;
}

section.top-home-section .top-home-box .standard-post {
    margin-bottom: 25px;
}

section.top-home-section .top-home-box .standard-post .image-holder {
    margin-bottom: 10px;
}

section.top-home-section .top-home-box .standard-post h2 {
    line-height: 20px;
}

.blog-section .single-post-image {
    margin-top: -50px;
    margin-bottom: 40px;
}

.blog-section .single-post-image .hover-post {
    bottom: 0;
    top: 0;
    display: flex;
    flex-direction: column;
    justify-content: center;
    text-align: center;
    align-items: center;
}

.blog-section .single-post-image h2 {
    color: #fff;
}

.blog-section .single-post-image img {
    animation: none;
}

.blog_section_main_detail {
    margin-top: 50px;
    padding-top: 40px !important;
}

.top_images_section_blog_main {
    margin-top: 0px !important;
}

@media (max-width: 991px) {
    .blog-section .single-post-image>img {
        height: 350px;
        width: auto;
    }
}

@media (max-width: 767px) {
    .blog-section .single-post-image>img {
        height: 300px;
        width: auto;
    }
}

@media (max-width: 640px) {
    .blog-section .single-post-image>img {
        height: 250px;
        width: auto;
    }
}

.comments__list,
ul.list-thumb-posts,
ul.post-tags,
ul.posts-list {
    margin: 0;
    padding: 0;
}

h1 {
    color: #333;
    font-size: 18px;
    font-family: Roboto, sans-serif;
    font-weight: 800;
    line-height: 32px;
    margin: 0 0 20px;
}


#container {
    overflow: hidden;
    opacity: 0;
}

a.text-link {
    color: #e74c3c;
    font-size: 11px;
    font-weight: 400;
    font-family: Roboto, sans-serif;
    text-transform: uppercase;
    letter-spacing: 0.5px;
}

ul.post-tags li {
    display: inline-block;
    color: #999;
    font-family: Roboto, sans-serif;
    font-weight: 400;
    font-size: 13px;
    line-height: 20px;
    margin-right: 5px;
}

ul.post-tags li a {
    color: #999;
}

ul.post-tags li a:hover {
    color: #e74c3c;
    text-decoration: underline !important;
}

ul.post-tags li:before {
    content: "|";
    color: #999;
    font-family: Roboto, sans-serif;
    font-weight: 400;
    font-size: 14px;
    margin-right: 8px;
}

ul.post-tags li:first-child:before {
    display: none;
    margin-right: 0;
}

a.button-one {
    color: #fd6f63;
    font-size: 13px;
    font-family: Roboto, sans-serif;
    padding: 13px 50px;
    background: #fff;
    font-weight: 400;
    border: 1px solid #fd6f63;
    transition: all 0.2s ease-in-out;
    -moz-transition: all 0.2s ease-in-out;
    -webkit-transition: all 0.2s ease-in-out;
    -o-transition: all 0.2s ease-in-out;
    text-transform: uppercase;
    margin: 0;
    border-radius: 20px;
}

a.button-one:hover {
    background: #fd6f63;
    border-color: #fd6f63;
    color: #fff;
}

.center-button {
    text-align: center;
    padding-top: 30px;
}

.image-post {
    position: relative;
    overflow: hidden;
}

.image-post img {
    width: 100%;
    height: auto;
    transition: all 0.6s ease-in-out;
    -moz-transition: all 0.6s ease-in-out;
    -webkit-transition: all 0.6s ease-in-out;
    -o-transition: all 0.6s ease-in-out;
}

.image-post:hover img {
    animation-name: imagemove;
    animation-duration: 3s;
    animation-timing-function: ease-out;
    animation-delay: 0s;
    animation-direction: alternate;
    animation-iteration-count: infinite;
    animation-fill-mode: none;
    animation-play-state: running;
}

@keyframes imagemove {
    0% {
        -webkit-transform: rotate(0) scale(1);
        -moz-transform: rotate(0) scale(1);
        -ms-transform: rotate(0) scale(1);
        -o-transform: rotate(0) scale(1);
        transform: rotate(0) scale(1);
    }

    100% {
        -webkit-transform: rotate(5deg) scale(1.1);
        -moz-transform: rotate(5deg) scale(1.1);
        -ms-transform: rotate(5deg) scale(1.1);
        -o-transform: rotate(5deg) scale(1.1);
        transform: rotate(5deg) scale(1.1);
    }
}

.standard-post {
    text-align: center;
}

.standard-post.left-align {
    text-align: left;
}

.standard-post .image-holder {
    margin-bottom: 14px;
    overflow: hidden;
}

.standard-post .image-holder:hover img {
    animation-name: imagemove;
    animation-duration: 3s;
    animation-timing-function: ease-out;
    animation-delay: 0s;
    animation-direction: alternate;
    animation-iteration-count: infinite;
    animation-fill-mode: none;
    animation-play-state: running;
}

@keyframes imagemove {
    0% {
        -webkit-transform: rotate(0) scale(1);
        -moz-transform: rotate(0) scale(1);
        -ms-transform: rotate(0) scale(1);
        -o-transform: rotate(0) scale(1);
        transform: rotate(0) scale(1);
    }

    100% {
        -webkit-transform: rotate(5deg) scale(1.1);
        -moz-transform: rotate(5deg) scale(1.1);
        -ms-transform: rotate(5deg) scale(1.1);
        -o-transform: rotate(5deg) scale(1.1);
        transform: rotate(5deg) scale(1.1);
    }
}

.standard-post img {
    width: 100%;
    height: auto;
    transition: all 0.6s ease-in-out;
    -moz-transition: all 0.6s ease-in-out;
    -webkit-transition: all 0.6s ease-in-out;
    -o-transition: all 0.6s ease-in-out;
}

.standard-post h2 {
    font-size: 18px;
    line-height: 24px;
    margin-bottom: 2px;
}

.standard-post h2 a {
    color: #333;
}

.standard-post h2 a:hover {
    color: #fd6f63;
}

@keyframes stretch {
    0% {
        padding: 10px;
    }

    100% {
        padding: 0;
    }
}

@keyframes imagemove {
    0% {
        -webkit-transform: rotate(0) scale(1);
        -moz-transform: rotate(0) scale(1);
        -ms-transform: rotate(0) scale(1);
        -o-transform: rotate(0) scale(1);
        transform: rotate(0) scale(1);
    }

    100% {
        -webkit-transform: rotate(5deg) scale(1.1);
        -moz-transform: rotate(5deg) scale(1.1);
        -ms-transform: rotate(5deg) scale(1.1);
        -o-transform: rotate(5deg) scale(1.1);
        transform: rotate(5deg) scale(1.1);
    }
}

@keyframes imagemove {
    0% {
        -webkit-transform: rotate(0) scale(1);
        -moz-transform: rotate(0) scale(1);
        -ms-transform: rotate(0) scale(1);
        -o-transform: rotate(0) scale(1);
        transform: rotate(0) scale(1);
    }

    100% {
        -webkit-transform: rotate(5deg) scale(1.1);
        -moz-transform: rotate(5deg) scale(1.1);
        -ms-transform: rotate(5deg) scale(1.1);
        -o-transform: rotate(5deg) scale(1.1);
        transform: rotate(5deg) scale(1.1);
    }
}

ul.posts-list>li {
    display: block;
    margin-bottom: 28px;
}

ul.posts-list>li a.text-link {
    margin-bottom: 0;
}

ul.posts-list>li h2 {
    font-size: 16px;
    font-weight: 400;
    margin-bottom: 0 !important;
    line-height: 24px;
}

ul.posts-list>li h2 a {
    color: #333;
}

ul.posts-list>li h2 a:hover {
    color: #fd6f63;
}

ul.list-thumb-posts>li {
    display: flex;
    padding-bottom: 24px;
    margin-bottom: 24px;
    border-bottom: 1px solid #ebebeb;
}

ul.list-thumb-posts>li:last-child {
    border-bottom: none;
    padding-bottom: 0;
    margin-bottom: 0;
}

ul.list-thumb-posts>li .image-holder {
    margin-right: 15px;
}

ul.list-thumb-posts>li .image-holder img {
    width: 60px;
    height: auto;
    border-radius: 5px;
}

ul.list-thumb-posts>li .list-post-content {
    margin-top: 0;
}

ul.list-thumb-posts>li h2 {
    font-size: 16px;
    font-family: Roboto, sans-serif;
    font-weight: 400;
    margin-bottom: 2px;
    line-height: 21px;
}

ul.list-thumb-posts>li h2 a {
    color: #333;
}

ul.list-thumb-posts>li h2 a:hover {
    color: #fd6f63;
}

section.fresh-section {
    padding: 60px 0 0;
}

section.fresh-section .fresh-box {
    padding-bottom: 50px;
    margin-left: -15px;
    margin-right: -15px;
}

section.fresh-section2 {
    padding: 30px;
}

section.fresh-section2 .news-post {
    margin-bottom: 30px;
}

.list_thumb_posts {
    display: flex;
    flex-wrap: wrap;
}

section.fresh-section2 ul.list-thumb-posts>li {
    padding-bottom: 0;
    border-bottom: none;
    margin-bottom: 30px;
    width: 33%;
}

.load_mod {
    display: flex;
    align-items: center;
    justify-content: center;
}

section.fresh-section2 a.button-one {
    margin-bottom: 20px;
}

section.trending-section {
    padding: 60px 0 70px;
}

section.blog-section {
    padding: 60px 0;
}

section.blog-section .blog-box .title-section h1 {
    padding-bottom: 16px;
    border-bottom: 1px solid #ebebeb;
    margin-bottom: 26px;
}

section.image-posts-section {
    padding-top: 30px;
}

.single-post .single-post-content>img {
    width: 100%;
    height: auto;
    margin-bottom: 20px;
}

.single-post .single-post-content .post-content {
    display: flex;
}

.single-post .single-post-content .post-content .post-content-text h1 {
    font-size: 24px;
    margin-bottom: 4px;
    background-color: transparent;
}

.single-post .single-post-content .post-content .post-content-text h1 span {

    background-color: transparent !important;
}

.single-post .single-post-content .post-content .post-content-text ul.post-tags {
    margin-bottom: 25px;

}



.single-post .single-post-content .post-content .post-content-text h3 {
    font-size: 18px;
    font-weight: 700;
    margin-bottom: 30px;
}

.single-post .single-post-content .post-content .post-content-text .image-alt-box {
    display: flex;
    justify-content: space-between;
    text-align: center;
}

.single-post .single-post-content .post-content .post-content-text .image-alt-box .image-holder {
    margin: 0 0 25px;
}

.single-post .single-post-content .post-content .post-content-text .image-alt-box .image-holder img {
    display: block;
    width: 325px;
    margin-bottom: 5px;
}

.comments h2.comments-title {
    font-size: 18px;
    padding-bottom: 20px;
    margin-bottom: 30px;
    border-bottom: 1px solid #ebebeb;
}

.blog-section .single-post-image {
    margin-top: -50px;
    margin-bottom: 40px;
}

.blog-section .single-post-image h2 {
    color: #fff;
}

.blog-section .single-post-image img {
    animation: none;
}

.description_section img {
    margin-bottom: 20px;
}

.posts_list_new {
    display: flex;
    /* font-style: italic; */
    flex-wrap: wrap;
}

.posts_list_new li {
    width: 48%;
    padding-right: 20px;
}

.posts_list_new ul.post-tags li {
    width: fit-content;
    padding-right: 3px;
}

.fresh_section2_new {
    background-color: #fafafa;
    padding: 20px !important;
}

.fresh_section2_new h2 {
    font-size: 18px;
    padding-bottom: 18px;
    margin-bottom: 20px;
    line-height: 22px;
    border-bottom: 1px solid #ebebeb;
    text-align: left;
}

.image_holder_com {
    width: 70px;
    height: 70px;
}

.image_holder_com img {
    width: 100% !important;
    height: 100% !important;
    object-fit: cover;
}

ul.list-thumb-posts>li .list_post_content {
    width: 80%;
    padding-right: 8px;
}

.list_posts_tag_d {
    justify-content: center;
    display: flex;
    flex-wrap: wrap;
}

.rlr_section_header_home_page {
    padding-bottom: 45px;
}

.top-home-box-common-overlay .news-post {
    position: relative;
}

.top-home-box-common-overlay .image-holder {
    position: relative;
}

.top-home-box-common-overlay .news-post .overlay {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    transform: scale(0);
    transition: 0.6s;
    opacity: 0;
    border-radius: 50%;
}

.top-home-box-common-overlay .news-post:hover .overlay {
    transform: scale(1);
    opacity: 1;
    border-radius: 0%;
}

.image-post .hover-post {
    z-index: 1;
}

.pagination_box {
    display: flex;
    width: 100%;
}

.pagination_box .left_nbtn_box {
    width: 80%;
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.pagination_box .circle_img {
    width: 60px;
    height: 60px;
    border-radius: 50%;
    overflow: hidden;
    margin-right: 20px;
}

.pagination_box .circle_img img {
    height: 100%;
    width: 100%;
    object-fit: cover;
}

.pagination_box_p {
    display: flex;
    width: 100%;
}

.pagination_box_p .left_nbtn_box {
    width: 80%;
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.pagination_box_p .circle_img {
    width: 60px;
    height: 60px;
    border-radius: 50%;
    overflow: hidden;
    margin-left: 20px;
}

.pagination_box_p .circle_img img {
    height: 100%;
    width: 100%;
    object-fit: cover;
}

.detail_page_blog_cat {
    padding-top: 50px;
}



@media (min-width: 576px) {
    .video_modal .modal-dialog {
        margin: 1.75rem auto;
        max-width: 900px !important;

    }


}

.video_modal .modal-content {
    padding: 0 !important;
    background-color: transparent !important;
}

.video_iframe {
    position: relative;
}

.video_iframe button {
    position: absolute;
    right: -20px;
    top: -20px;
    background-color: var(--brand-07) !important;
    border-radius: 50%;
    color: #fff;
    border-color: var(--brand-07);
    font-size: 16px;
}

.video_iframe button:hover {
    border-color: var(--brand-07);
}

.video_iframe iframe {
    height: 500px;
}

.blog_section_main_bg {
    background-image: none !important;
}

@media (max-width:1366px) {

    .mt-4 {
        margin-top: 0% !important;
    }

}

@media(max-width: 1199px) {
    .single-post .single-post-content .post-content .post-content-text .image-alt-box .image-holder img {
        width: 265px;
    }

    .mt-4 {
        margin-top: 0% !important;
    }
}

@media (max-width: 991px) {
    .single-post .single-post-content .post-content .post-content-text .image-alt-box .image-holder img {
        width: 305px;
    }

    .blog-section .single-post-image>img {
        height: 350px;
        width: auto;
    }

    .mt-4 {
        margin-top: 0% !important;
    }
}

@media (max-width: 767px) {
    section.image-posts-section .image-post {
        margin-bottom: 30px;
    }

    .single-post .single-post-content .post-content .post-content-text .image-alt-box {
        display: block;
    }

    .single-post .single-post-content .post-content .post-content-text .image-alt-box .image-holder img {
        width: 100%;
    }

    .blog-section .single-post-image>img {
        height: 300px;
        width: auto;
    }

    .video_iframe button {
        right: 0 !important;
    }
}

@media (max-width: 640px) {
    .blog-section .single-post-image>img {
        height: 250px;
        width: auto;
    }

    .image_post_blogdetail_one {
        height: auto;
    }

    .standard_post_left_align .image-holder {
        height: auto;
    }

    .image_post_blogdetail_two {
        height: auto;
    }

    section.fresh-section2 ul.list-thumb-posts>li {
        width: 100%;
    }

    .top_home_section .pb-4 {
        padding-bottom: 0 !important;
    }

    .rlr_section_header_home_page {
        padding-bottom: 15px;
    }

    section.fresh-section2 a.button-one {
        margin-bottom: 0;
    }

    .list_posts_tag_d {
        padding-left: 0;
    }

    .rlr-footer__legal__row .rlr-footer__legal__row__col p:nth-child(1),
    .rlr-footer__legal__row .rlr-footer__legal__row__col p:nth-child(2),
    .rlr-footer__legal__row .rlr-footer__legal__row__col p:nth-child(3),
    .rlr-footer__legal__row .rlr-footer__legal__row__col p:nth-child(4) {
        display: none;
    }

    .rlr-footer__legal__row .rlr-footer__legal__row__col a {
        margin: 5px;
    }

    section.top-images-section .news-post {
        height: auto;
    }

    .trending_posts_section {
        height: 60vh;
    }

    .trending_posts_section img {
        height: 100% !important;
        object-fit: cover;
    }

    .center-button {

        padding-top: 10px;

    }

    .sidebar_new {
        padding-top: 50px;
    }

    section.top-home-section .top-home-box .standard-post .image-holder {
        margin-bottom: 20px;
    }

    .load_mod {
        padding-top: 20px;
    }

    section.fresh-section2 {
        padding-bottom: 0;
    }

    .single-post .single-post-content .post-content {
        display: flex;
        flex-wrap: wrap;
    }

    .single-post .single-post-content .post-content .post-social {
        text-align: left;
        width: 100%;
        margin-right: 0;
        /* display: flex; */
    }

    .single-post .single-post-content .post-content .post-social .share-post {
        display: flex;
        align-items: center;
        padding-left: 0;
    }

    .single-post .single-post-content .post-content .post-social ul.share-post li a {
        display: flex;
        align-items: center;
        justify-content: center;
    }

    .single-post .single-post-content .post-content .post-social ul.share-post li {
        margin-right: 10px;
    }

    .single-post .single-post-content .post-content .post-content-text blockquote {
        font-size: 20px;
    }

    .single-post .single-post-content .post-content .post-content-text .share-tags-box ul.tags li {
        margin-bottom: 8px;
    }

    section.blog-section {

        padding-bottom: 0;
    }

    .blog_section_main {
        margin-top: 80px;
    }

    .top_images_section_blog_main .news-post {
        margin: 15px !important;
    }

    section.top-home-section .image_post_blogdetail_two_m {
        margin-bottom: 0 !important;
    }

    .load_mod.pt-4 {
        padding-top: 1rem !important;
    }

    .trending_posts_section.image-post .hover-post {
        height: 100%;
    }

    section.trending-section {
        padding: 50px 0 30px;
    }

    section.fresh-section {
        padding: 50px 0 0;
    }

    section.top-images-section {

        padding: 20px 0 50px;
    }

    .blog_section_post {
        padding-top: 40px !important;
    }

    .article-post h2 {
        font-size: 1.2rem;
    }

    .top_home_section {
        margin-top: 50px;
    }

    .blog_section_main_detail {
        margin-top: 30px;
    }

    .single-post .single-post-content .post-content .post-social {
        position: relative;
        top: 0;
    }

    .single-post .single-post-content .prev-next-box {
        justify-content: center;
        flex-direction: column;
    }

    .single-post .single-post-content .prev-next-box .prev-box,
    .single-post .single-post-content .prev-next-box .next-box {
        width: 100%;
    }

    .detail_page_blog_cat {
        padding-top: 10px;
    }

    .blog_one_slider .owl-dots {
        display: none;
    }

    .image-post .hover-post h2 {
        /* margin-bottom: 5px; */
        font-size: 1rem;
    }

    .video-post h2 {
        font-size: 1rem;
    }

    section.video-section {
        padding: 50px 0 35px;
    }

    .row-cols-xl-2>* {
        margin-bottom: 25px !important;
    }

    section.fresh-section2 {
        padding: 40px 5px;
    }

    .load_mod.mt-4 {
        margin-top: 0 !important;
    }

    .blog-section,
    .top-images-section {
        margin-top: 0;
        padding-top: 0 !important;
    }

    .top_images_section_blog_main {
        margin-top: 70px !important;
    }

    .check_more_country {
        margin-bottom: 20px;
    }

    .video_iframe iframe {
        height: 250px;
    }

    .blog_section_post {
        padding-top: 10px !important;
    }

    .blog_section_main_detail {
        margin-top: 70px !important;
    }
}

@media (max-width:576px) {

    a.button-one {

        border-radius: 20px !important;
        padding: 5px 30px;
    }

    .sidebar_new {

        padding-top: 30px;

    }

    .sidebar .widget.social-widget>h2 {


        text-align: center;
        letter-spacing: 1px;
        font-weight: 600;
    }

    a.text-link {
        font-size: 14px;
        margin-bottom: 2px;
    }

    .list_posts_tag a {
        font-size: 8px;
    }

    .h-90px {
        height: 50px !important;
    }

    .w-90px {

        width: 50px !important;
    }

    .h-40px {
        height: 25px !important;
    }

    .w-40px {
        width: 25px !important;
    }

    .social_media_box li a {

        width: 40px;
        height: 40px;

    }

    .top_images_section_blog_main {
        margin-top: 0px !important;
    }

    .list_ {
        line-height: 8px !important;
    }

    .center-button {
        padding-top: 0px;
    }

    .article-post h2 {

        line-height: 18px;
    }

    .article-post p {
        margin-top: 0px !important;
    }

    section {

        padding-top: 20px;
    }

    .md-ls-minus-2px {
        font-size: 22px;
    }

    .page-title-extra-large h1 {
        font-size: 38px;
        letter-spacing: 1px;
        margin-top: 40px !important;
    }

    .mt-4 {
        margin-top: 0px !important;
    }

    .extra-very-small-screen {
        height: 234px !important;

    }


    .lg-p-13 {
        padding: 6% !important;
    }


    .blog_section_main_detail {

        margin-top: 0px !important;
    }

    .single-post .single-post-content .post-content .post-social {
        text-align: center;
        margin-bottom: 20px;


    }

    .single-post .single-post-content .post-content .post-social .share-post {
        display: flex;
        justify-content: center;
        align-items: center;
    }

    .single-post .single-post-content .post-content .post-social span {
        font-size: 17px;
        font-weight: 600;
    }

    .single-post .single-post-content .post-content .post-content-text h2 {

        margin: 5px 0;
        padding: 10px 7px;
        line-height: 30px;
        font-size: 20px;
    }

    .single-post .single-post-content .post-content .post-content-text ul.post-tags {
        margin-bottom: 5px;
    }

    .single-post .single-post-content .post-content .post-content-text h1 {
        margin-bottom: 0px !important;
        font-size: 18px;

    }

    .single-post .single-post-content .prev-next-box {
        padding: 0px;
    }

    header .header-icon .btn.btn-rounded.btn-small {
        display: none;
    }

    .single-post .single-post-content .post-content .post-content-text p {

        margin-bottom: 6px;
    }

    .single-post .single-post-content .post-content .post-content-text h1 span {
        font-size: 15px !important;
    }

    .sm-w-100 {
        text-align: left;

    }


    .cover_background_home_mobile_none {
        display: none;
    }

    .blog_section_main_bg {
        background-image: url('../../assets/images/download_banner.jpg') !important;
        background-repeat: no-repeat;
        padding-top: 100px !important;
    }

    .blog_section_main_bg .post-social {
        display: none;

    }
    .sidebar .widget ul.list-posts>li.list_ .img-thumb img {
        width: 60px;
        height: 50px;
    }

}







@media (max-width:320px) {

    .single-post .single-post-content .post-content .post-social span {
        font-size: 23px;
    }

    .single-post .single-post-content .post-content .post-social {

        text-align: center;
    }

    .single-post .single-post-content .post-content .post-social .share-post {
        display: flex;
        align-items: center;
        justify-content: center;
    }

    .rlr-section__title--main {
        font-size: 19px;
    }

    .single-post .single-post-content .post-content .post-content-text h2 {
        padding: 1px 0px;


    }

    .page-title-extra-large h1 {

        font-size: 45px;
        margin-top: 25px;
        letter-spacing: 2px;
    }

    section.top-images-section {
        margin-top: 0px !important;
    }

}